// auth.guard.ts
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { EncryptDataService } from '../security/encrypt-data.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(
		private us: UserService,
		private router: Router,
		private securityLocalStorage: EncryptDataService
	) {}

		canActivate(
			route: ActivatedRouteSnapshot,
			state: RouterStateSnapshot
		): boolean {
			try {
			// Obtener y desencriptar los datos almacenados en localStorage
			const encryptedData = localStorage.getItem('data');
			if (encryptedData) {
				const data = JSON.parse(this.securityLocalStorage.decryptData(encryptedData));

				// Autenticar al usuario y redirigir según su rol
				// this.us.role = data.result.id_user_type;
				switch ( data.result.id_user_type) {
					case 2:
						this.router.navigate(['/admin/locker']);
						break;

					case 6:
						this.router.navigate(['/admin/locker']);
						break;
					case 3:
						this.router.navigate(['/clients/orders']);
						break;
					// case 10:
					// 	this.router.navigate(['/monitoreo/overview']);
					// 	break;
					default:
						this.router.navigate(['/403']);
						return false;
				}
				return true;
			} else {
				this.router.navigate(['/auth']);
				return false;
			}
		} catch (error) {
			console.error('Error al autenticar al usuario:', error);
			this.router.navigate(['/403']);
			return false;
		}
	}
}
