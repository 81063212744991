import { Component, Input, OnInit, inject } from '@angular/core';
import { TranslateService } from 'src/app/core/services/translate.service';

@Component({
	selector: 'app-button-dashboard',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonDashboardComponent implements OnInit {

	@Input() buttonClass: string = 'btn-sm-primary'; // Valor por defecto
  	@Input() label: string = ''; // Texto del label
	@Input() icon: string = '';
	@Input() disabled: boolean = false;
	@Input() colorIcon:string = '';

	constructor(private translate: TranslateService){

	}
	ngOnInit(): void {
	}
}
